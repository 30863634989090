<template>
  <div class="content">
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-layout">
        <div
          class="md-layout-item md-xsmall-size-100 md-small-size-33 md-medium-size-25 md-large-size-15 md-xlarge-size-10"
        >
          <dialog-source-dataset></dialog-source-dataset>
        </div>
        <div
          class="md-layout-item md-xsmall-size-100 md-small-size-33 md-medium-size-25 md-large-size-15 md-xlarge-size-10"
        >
          <dialog-type-dataset></dialog-type-dataset>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div
        v-for="dataset in datasets"
        :key="dataset.id"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="warning">
          <template slot="header">
            <div class="card-icon">
              <router-link
                :to="
                  `/mosaico/datasets/list/${dataset.source.name}/${dataset.name}`
                "
              >
                <div class="md-icon md-icon-img">
                  <img
                    :src="`${apiUrl}${dataset.source.logo}`"
                    :alt="`${dataset.source.alias}`"
                  /></div
              ></router-link>
            </div>
            <p class="category">Fonte: {{ dataset.source.alias }}</p>
            <router-link
              :to="
                `/mosaico/datasets/list/${dataset.source.name}/${dataset.name}`
              "
            >
              <h3 class="title">{{ dataset.alias }}</h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Atualizado em: {{ dataset.lastUpdate }}
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StatsCard,
  DialogSourceDataset,
  DialogTypeDataset
} from "@/components";
import Vue from "vue";

export default {
  components: {
    StatsCard,
    DialogSourceDataset,
    DialogTypeDataset
  },
  data() {
    return {
      lastUpdate: [],
      datasets: [],
      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/`
    };
  },
  props: {
    brasilIo: {
      type: String,
      default: require("@/assets/img/logo-brasil-io.png")
    },
    sesapi: {
      type: String,
      default: require("@/assets/img/sesapi_logo.png")
    },
    cnes: {
      type: String,
      default: require("@/assets/img/logo_cnes.png")
    },
    sus: {
      type: String,
      default: require("@/assets/img/sus-logo.svg")
    }
  },
  created() {
    this.getDataSets();
  },
  mounted() {
    this.welcomeMessage();
  },
  methods: {
    welcomeMessage() {
      this.$emit("welcomeMessage", {
        title: "Coleção de datasets"
      });
    },
    async getDataSets() {
      try {
        let result = await Vue.$http.get(`v1/type-dataset?all=true`);
        this.datasets = result.data;
        await this.datasets.forEach(async item => {
          if (item.source.logo != null) {
            item.source.logo = await item.source.logo.replace(
              "public",
              "storage"
            );
          }
        });
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.md-icon-img {
  min-width: 52px;
  min-height: 52px;
}
</style>
